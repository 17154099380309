<div>
  <div class="air__utils__textDivider mb-2">
    <h4 class="air__utils__textDivider__content font-size-24 font-weight-bold">
      AWAITING YOUR APPROVAL
    </h4>
  </div>
  <nz-table
    #basicTable
    [nzShowPagination]="false"
    [nzData]="listOfAllData"
    (nzCurrentPageDataChange)="currentPageDataChange($event)"
    class="air__utils__scrollTable mb-4"
  >
    <thead>
    <tr>
      <th class="text-uppercase">Reference</th>
      <th class="text-uppercase">Type</th>
      <th class="text-uppercase text-right">Value</th>
      <th class="text-uppercase">Description</th>
      <th class="text-uppercase"></th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of basicTable.data">
      <td>
        {{data.actionName}}
      </td>
      <td>
        <a href="javascript: void(0);" class="text-blue">
          {{data.location}}
        </a>
      </td>
      <td class="text-right font-weight-bold">
        {{data.value}}
      </td>
      <td>
        {{data.description}}
      </td>

      <td class="text-right">
        <button type="button" class="btn btn-outline-success mr-2 mb-2">Approve</button>
        <button type="button" class="btn btn-outline-danger mr-2 mb-2">Reject</button>
      </td>
    </tr>
    </tbody>
  </nz-table>
</div>
