<div>
  <ul class="list-unstyled">
    <li class="item">
      <a href="#" class="itemLink">
        <div class="itemPic">
          <i class="fe fe-file-text"></i>
        </div>
        <div class="mr-2">
          <div>Payment Received</div>
          <div class="text-muted">3 minutes ago</div>
        </div>
        <div class="itemAction">
          <span></span>
        </div>
      </a>
    </li>
    <li class="item">
      <a href="#" class="itemLink">
        <div class="itemPic">
          <i class="fe fe-mail"></i>
        </div>
        <div class="mr-2">
          <div>Message Removed</div>
          <div class="text-muted">2 hours ago</div>
        </div>
        <div class="itemAction">
          <span></span>
        </div>
      </a>
    </li>
    <li class="item">
      <a href="#" class="itemLink">
        <div class="itemPic">
          <i class="fe fe-grid"></i>
        </div>
        <div class="mr-2">
          <div>Parcel Received</div>
          <div class="text-muted">6 hours ago</div>
        </div>
        <div class="itemAction">
          <span></span>
        </div>
      </a>
    </li>
    <li class="item">
      <a href="#" class="itemLink">
        <div class="itemPic">
          <i class="fe fe-database"></i>
        </div>
        <div class="mr-2">
          <div>Parcel Recived</div>
          <div class="text-muted">15 hours ago</div>
        </div>
        <div class="itemAction">
          <span></span>
        </div>
      </a>
    </li>
    <li class="item">
      <a href="#" class="itemLink">
        <div class="itemPic">
          <i class="fe fe-flag"></i>
        </div>
        <div class="mr-2">
          <div>User Activated</div>
          <div class="text-muted">2 days ago</div>
        </div>
        <div class="itemAction">
          <span></span>
        </div>
      </a>
    </li>
  </ul>
</div>
