import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';
import { MenuService } from 'src/app/services/menu.service';
import * as Reducers from 'src/app/store/reducers';
import * as SettingsActions from 'src/app/store/settings/actions';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'air-menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss'],
  animations: [
    trigger('flyoutAnimation', [
      transition(':enter', [
        style({ transform: 'translate3d(0, calc(-50% + 40px), 0)' }),
        animate('100ms ease-in-out', style({ transform: 'translate3d(0, calc(-50% + 20px), 0)' })),
      ]),
    ]),
  ],
})
export class MenuLeftComponent implements OnInit {
  public menuData: any = [];
  public isMobileView: boolean;
  public isMobileMenuOpen: boolean;
  public isMenuCollapsed: boolean;
  public isMenuUnfixed: boolean;
  public isMenuShadow: boolean;
  public isSidebarOpen: boolean;
  public menuType: string;
  public menuColor: string;
  public flyoutMenuColor: string;
  public menuLayoutType: string;
  public loggedInUser: any;

  public activeSubmenu = '';
  public activeItem = '';
  public renderedFlyoutItems: object = {};
  public flyoutTimers: object = {};
  public flyoutActive = false;
  public objectKeys = Object.keys;

  constructor(
    private menuService: MenuService,
    private store: Store<any>,
    public user: AuthService,
    private router: Router,
  ) {}

  public ngOnInit() {
    this.menuService.getMenuData().subscribe((menuData) => (this.menuData = menuData));
    this.store.pipe(select(Reducers.getSettings)).subscribe((state) => {
      this.isMobileView = state.isMobileView;
      this.isMobileMenuOpen = state.isMobileMenuOpen;
      this.isMenuCollapsed = state.isMenuCollapsed;
      this.isMenuUnfixed = state.isMenuUnfixed;
      this.isMenuShadow = state.isMenuShadow;
      this.menuType = state.menuType;
      this.menuColor = state.menuColor;
      this.flyoutMenuColor = state.flyoutMenuColor;
      this.menuLayoutType = state.menuLayoutType;
      this.isSidebarOpen = state.isSidebarOpen;
      this.flyoutActive =
        (state.menuType === 'flyout' || state.menuType === 'compact' || state.isMenuCollapsed) &&
        !state.isMobileView;
    });
    this.setActiveItems(this.router.url);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.setActiveItems(event.url ? event.url : null);
      });
  }

  public toggleMobileMenu() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isMobileMenuOpen: !this.isMobileMenuOpen,
      }),
    );
  }

  public toggleMenu() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isMenuCollapsed: !this.isMenuCollapsed,
      }),
    );
  }

  public toggleSettings() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isSidebarOpen: !this.isSidebarOpen,
      }),
    );
  }

  public handleSubmenuClick(key: string) {
    const currentKey = this.activeSubmenu;
    if (this.flyoutActive) {
      return;
    }
    this.activeSubmenu = currentKey === key ? '' : key;
  }

  public setActiveItems(pathname) {
    const menuData = this.menuData;
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item);
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key));
        }
        return flattenedItems;
      }, []);
    const activeItem = _.find(flattenItems(menuData, 'children'), ['url', pathname]) || {};
    const activeSubmenu = menuData.reduce((key, parent) => {
      if (Array.isArray(parent.children)) {
        parent.children.map((child) => {
          if (child.key === activeItem.key) {
            key = parent;
          }
          return '';
        });
      }
      return key;
    });

    this.activeItem = activeItem.key;
    this.activeSubmenu = activeSubmenu.key;
  }

  public handleFlyoutOver(event, key, items) {
    if (this.flyoutActive) {
      clearInterval(this.flyoutTimers[key]);
      const item = event.currentTarget;
      const itemDimensions = item.getBoundingClientRect();
      this.renderedFlyoutItems = {
        ...this.renderedFlyoutItems,
        [key]: {
          key,
          itemDimensions,
          items,
        },
      };
    }
  }

  public handleFlyoutOut(key) {
    if (this.flyoutActive) {
      this.flyoutTimers[key] = setTimeout(() => {
        const updatedFlyoutItems = Object.assign({}, this.renderedFlyoutItems);
        delete updatedFlyoutItems[key];
        this.renderedFlyoutItems = {
          ...updatedFlyoutItems,
        };
      }, 100);
    }
  }

  public checkIsSuperUser(item: any) {

    if (item.guard) {
      const user = JSON.parse(localStorage.getItem('user'));
      return item.guard.find((element) => element === user.email);
    } else {
      return true;
    }

  }

  public handleFlyoutContainerOver(key) {
    clearInterval(this.flyoutTimers[key]);
  }
}
