import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'air-list-14',
  templateUrl: './14.component.html',
})
export class AirList14Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
