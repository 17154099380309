import {Component, Input, OnInit} from '@angular/core'

@Component({
  selector: 'air-general-10v1',
  templateUrl: './10v1.component.html',
})
export class AirGeneral10v1Component implements OnInit {

  @Input() info: any;

  constructor() {}
  ngOnInit() {}
}
