import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-apps-amend',
  templateUrl: './2v2.component.html',
})
export class AirGeneral2v2Component implements OnInit {
  constructor() {}

  stat: number = 0

  ngOnInit() {
    this.getStat()
  }

  getStat() {



  }
}
