import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'air-general-13',
  templateUrl: './13.component.html',
  styleUrls: ['./13.component.scss'],
})
export class AirGeneral13Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
