<div>
  <div class="font-weight-bold text-dark font-size-24">78,367</div>
  <div>Total Sales</div>
  <x-chartist
    [data]="chartData"
    [type]="'Line'"
    [options]="chartOptions"
    class="height-200"
  >
  </x-chartist>
</div>
