export class CurationRules {

  public byMerchant: number[];
  public byProductCategory: number[];
  public logic: 'AND' | 'OR';
  public genuineJersey: boolean;
  public pinProducts: boolean;
  public ordering: string;

}
