<ng-container *ngFor="let item of objectKeys(renderedFlyoutItems)">
  <ng-container *ngTemplateOutlet="flyoutTpl; context: {$implicit: renderedFlyoutItems[item]}"></ng-container>
</ng-container>
<div [ngClass]="{
    air__menuTop: true,
    air__menuTop__mobileToggled: isMobileMenuOpen,
    air__menuTop__toggled: isMenuCollapsed,
    air__menuTop__unfixed: isMenuUnfixed,
    air__menuTop__shadow: isMenuShadow,
    air__menuTop__flyout: menuType === 'flyout',
    air__menuTop__compact: menuType === 'compact',
    air__menuTop__blue: menuColor === 'blue',
    air__menuTop__white: menuColor === 'white',
    air__menuTop__gray: menuColor === 'gray',
    air__menuFlyout__black: flyoutMenuColor === 'dark' && menuType !== 'default',
    air__menuFlyout__white: flyoutMenuColor === 'white' && menuType !== 'default',
    air__menuFlyout__gray: flyoutMenuColor === 'gray' && menuType !== 'default'
  }">
  <div class="air__menuTop__outer">
    <a href="javascript: void(0);" class="air__menuTop__mobileToggleButton" (click)="toggleMobileMenu()">
      <span></span>
    </a>
    <a href="javascript: void(0);" class="air__menuTop__logo">
      <img src="assets/images/air-logo.png" alt="Air UI" />
      <div class="air__menuTop__logo__name">AIR UI</div>
      <div class="air__menuTop__logo__descr">Admin Template</div>
    </a>
    <perfect-scrollbar>
      <div class="air__menuTop__container">
        <ul class="air__menuTop__list">
          <li class="air__menuTop__item">
            <a href="javascript: void(0);" class="air__menuTop__link" (click)="toggleSettings()">
              <i class="fe fe-settings air__menuTop__icon"></i>
              <span>Settings</span>
            </a>
          </li>
          <li class="air__menuTop__item">
            <a href="https://docs.airuitemplate.com/" class="air__menuTop__link" target="_blank"
              rel="noopener noreferrer">
              <i class="fe fe-compass air__menuTop__icon"></i>
              <span>Documentation</span>
            </a>
          </li>
          <ng-container *ngFor="let item of menuData">
            <ng-container *ngTemplateOutlet="itemTpl; context: {$implicit: item}"></ng-container>
            <ng-container *ngTemplateOutlet="submenuTpl; context: {$implicit: item}"></ng-container>
          </ng-container>
        </ul>
      </div>
    </perfect-scrollbar>
  </div>
</div>
<a href="javascript: void(0);" class="air__menuTop__backdrop" (click)="toggleMobileMenu()"></a>

<ng-template #itemTpl let-item>
  <ng-container *ngIf="!item.children && !item.category">
    <li class="air__menuTop__item" [ngClass]="{air__menuTop__item__active: item.key === activeItem}">
      <a [routerLink]="item.url ? item.url : 'javascript: void(0)'" class="air__menuTop__link">
        <i *ngIf="item.icon" class="air__menuTop__icon" [ngClass]="item.icon"></i>
        <span>{{ item.title }}</span>
      </a>
    </li>
  </ng-container>
</ng-template>
<ng-template #submenuTpl let-item>
  <ng-container *ngIf="item.children">
    <li class="air__menuTop__item air__menuTop__submenu"
      [ngClass]="{air__menuTop__submenu__active: item.key === activeSubmenu}">
      <a href="javascript: void(0);" class="air__menuTop__link" (click)="handleSubmenuClick(item.key)"
        (mouseenter)="handleFlyoutOver($event, item.key, item.children)" (mouseleave)="handleFlyoutOut(item.key)">
        <i *ngIf="item.icon" class="air__menuTop__icon" [ngClass]="item.icon"></i>
        <span>{{ item.title }}</span>
      </a>
      <ul class="air__menuTop__list">
        <ng-container *ngFor="let item of item.children">
          <ng-container *ngTemplateOutlet="itemTpl; context: {$implicit: item}"></ng-container>
        </ng-container>
      </ul>
    </li>
  </ng-container>
</ng-template>
<ng-template #flyoutTpl let-item>
  <div @flyoutAnimation [ngStyle]="{
        left: item.itemDimensions.left + item.itemDimensions.width / 2 + 'px',
        top: item.itemDimensions.top + item.itemDimensions.height + 'px'
      }" class="air__menuFlyout" [ngClass]="{
      air__menuFlyoutTop: menuLayoutType === 'top',
      air__menuFlyout__black: flyoutMenuColor === 'dark',
      air__menuFlyout__white: flyoutMenuColor === 'white',
      air__menuFlyout__gray: flyoutMenuColor === 'gray'
    }">
    <ul class="air__menuTop__list" (mouseenter)="handleFlyoutContainerOver(item.key)"
      (mouseleave)="handleFlyoutOut(item.key)">
      <ng-container *ngFor="let item of item.items">
        <ng-container *ngTemplateOutlet="itemTpl; context: {$implicit: item}"></ng-container>
      </ng-container>
    </ul>
  </div>
</ng-template>