import {Component, Input, OnInit} from '@angular/core'

@Component({
  selector: 'air-list-19',
  templateUrl: './19.component.html',
  styleUrls: ['./19.component.scss'],
})
export class AirList19Component implements OnInit {
  @Input() info: any;

  constructor() {}
  ngOnInit() {}
}
