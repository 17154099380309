<div>
  <div class="d-flex mb-1">
    <div class="text-dark text-uppercase font-weight-bold mr-auto">
      {{label}}
    </div>
    <div class="text-gray-6">
      {{percentage}}% Goal Reached
    </div>
  </div>
  <div class="d-flex mb-2">
    <div class="text-success font-size-24 font-weight-bold mr-auto">{{stat}}</div>
    <div class="text-gray-4 font-size-24">{{currentGoal | number}}</div>
  </div>
  <div class="progress">
    <div
      class="progress-bar bg-success"
      [style.width]="percentage +'%'"
      role="progressbar"
    ></div>
  </div>
</div>
