<div>
  <ul class="list list-unstyled">
    <li class="item">
      <a href="javascript: void(0);" class="itemLink">
        <div class="itemMeta">
          <div class="air__utils__donut air__utils__donut--gray-2 air__utils__donut--md"></div>
        </div>
        <div class="mr-3">
          <div>Payment Received</div>
          <div class="text-muted">Mary has approved your quote.</div>
        </div>
        <div class="itemAction">
          <span></span>
          <span></span>
        </div>
      </a>
    </li>
    <li class="item">
      <a href="javascript: void(0);" class="itemLink">
        <div class="itemMeta">
          <div class="air__utils__donut air__utils__donut--success air__utils__donut--md"></div>
        </div>
        <div class="mr-3">
          <div>Account Activated</div>
          <div class="text-muted">Mary has approved your quote.</div>
        </div>
        <div class="itemAction">
          <span></span>
          <span></span>
        </div>
      </a>
    </li>
    <li class="item">
      <a href="javascript: void(0);" class="itemLink">
        <div class="itemMeta">
          <div class="air__utils__donut air__utils__donut--danger air__utils__donut--md"></div>
        </div>
        <div class="mr-3">
          <div>User Deleted</div>
          <div class="text-muted">Mary has approved your quote.</div>
        </div>
        <div class="itemAction">
          <span></span>
          <span></span>
        </div>
      </a>
    </li>
    <li class="item">
      <a href="javascript: void(0);" class="itemLink">
        <div class="itemMeta">
          <div class="air__utils__donut air__utils__donut--gray-2 air__utils__donut--md"></div>
        </div>
        <div class="mr-3">
          <div>Message Received</div>
          <div class="text-muted">Mary has approved your quote.</div>
        </div>
        <div class="itemAction">
          <span></span>
          <span></span>
        </div>
      </a>
    </li>
    <li class="item">
      <a href="javascript: void(0);" class="itemLink">
        <div class="itemMeta">
          <div class="air__utils__donut air__utils__donut--info air__utils__donut--md"></div>
        </div>
        <div class="mr-3">
          <div>Account Activated</div>
          <div class="text-muted">Mary has approved your quote.</div>
        </div>
        <div class="itemAction">
          <span></span>
          <span></span>
        </div>
      </a>
    </li>
    <li class="item">
      <a href="javascript: void(0);" class="itemLink">
        <div class="itemMeta">
          <div class="air__utils__donut air__utils__donut--gray-2 air__utils__donut--md"></div>
        </div>
        <div class="mr-3">
          <div>Account Activated</div>
          <div class="text-muted">Mary has approved your quote.</div>
        </div>
        <div class="itemAction">
          <span></span>
          <span></span>
        </div>
      </a>
    </li>
  </ul>
</div>
