<div class="topbarDark">
  <a href="javascript: void(0);" class="logo d-none d-md-block">
    <img src="assets/images/air-logo.png" alt="Air UI" />
    <div class="logoName">AIR UI</div>
    <div class="logoDescr">Admin Template</div>
  </a>
  <div class="mr-3 d-block">
    <air-topbar-dark-menu-dashboards></air-topbar-dark-menu-dashboards>
  </div>
  <div class="mr-auto d-block">
    <air-topbar-dark-menu-pages></air-topbar-dark-menu-pages>
  </div>
  <div class="mb-0 mr-4 d-xl-block d-none">
    <air-topbar-dark-status></air-topbar-dark-status>
  </div>
  <div class="mr-4 d-none d-md-block">
    <air-topbar-dark-language-switcher></air-topbar-dark-language-switcher>
  </div>
  <div class="mr-4 d-none d-md-block">
    <air-topbar-dark-actions></air-topbar-dark-actions>
  </div>
  <div class="d-none d-md-block">
    <air-topbar-dark-user-menu></air-topbar-dark-user-menu>
  </div>
</div>