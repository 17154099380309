import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AppPreloader } from 'src/app/app-routing-loader';
import { AuthGuard } from 'src/app/components/layout/Guard/auth.guard';
import { LayoutsModule } from 'src/app/layouts/layouts.module';
import { SharedModule } from 'src/app/shared.module';

// layouts & notfound
import { LayoutAppComponent } from 'src/app/layouts/App/app.component';
import { LayoutAuthComponent } from 'src/app/layouts/Auth/auth.component';
import { NotFoundComponent } from 'src/app/pages/404.component';

const COMPONENTS = [NotFoundComponent];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutAppComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('src/app/pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'curations',
        loadChildren: () =>
          import('src/app/pages/curations/curations.module').then((m) => m.CurationsModule),
      },
      {
        path: 'curations',
        loadChildren: () =>
          import('src/app/pages/curations/curations.module').then((m) => m.CurationsModule),
      },
      {
        path: 'curations',
        loadChildren: () =>
          import('src/app/pages/curations/curations.module').then((m) => m.CurationsModule),
      },
      {
        path: 'categories',
        loadChildren: () =>
          import('src/app/pages/categories/categories.module').then((m) => m.CategoriesModule),
      },
      {
        path: 'sections',
        loadChildren: () =>
          import('src/app/pages/sections/section.module').then((m) => m.SectionModule),
      },
      {
        path: 'merchants',
        loadChildren: () =>
          import('src/app/pages/merchants/merchants.module').then((m) => m.MerchantsModule),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('src/app/pages/reporting/reporting.module').then((m) => m.ReportingModule),
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('src/app/pages/orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: 'payouts',
        loadChildren: () =>
          import('src/app/pages/payouts/payouts.module').then((m) => m.PayoutsModule),
      },
      {
        path: 'vouchers',
        loadChildren: () =>
          import('src/app/pages/vouchers/vouchers.module').then((m) => m.VouchersModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('src/app/pages/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'discounts',
        loadChildren: () =>
          import('src/app/pages/discounts/discounts.module').then((m) => m.DiscountsModule),
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('src/app/pages/cutomers/customers.module').then((m) => m.CustomersModule),
      },
    ],
  },
  {
    path: 'system',
    component: LayoutAuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/system/system.module').then((m) => m.SystemModule),
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [AuthGuard],
    data: { title: 'Not Found' },
  },
  {
    path: 'notAuthorised',
    component: NotFoundComponent,
    canActivate: [AuthGuard],
    data: { title: 'Not Found' },
  },
];

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      useHash: true,
      preloadingStrategy: AppPreloader,
    }),
    LayoutsModule,
  ],
  providers: [AppPreloader],
  declarations: [...COMPONENTS],
  exports: [RouterModule],
})
export class AppRoutingModule {}
