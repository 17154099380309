import {Component, OnInit} from '@angular/core'
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'air-subbar',
  templateUrl: './subbar.component.html',
  styleUrls: ['./subbar.component.scss'],
})
export class SubbarComponent implements  OnInit{

  constructor(public activeRoute: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.activeRoute.data.subscribe(data => {
      console.log(data);
    });

  }

}
