import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'air-general-2v4',
  templateUrl: './2v4.component.html',
})
export class AirGeneral2v4Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
