import {environment} from '../../environments/environment';

export const getMenuData: any[] = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    icon: 'fe fe-bookmark',
    url: '/dashboard',
  },
  {
    title: 'Operations',
    key: 'operations',
    icon: 'fa fa-users',
    url: '',
    children: [
      {
        title: 'Customers',
        key: 'customners',
        icon: 'fa fa-users',
        url: '/customers',
      },
      {
        title: 'Merchants',
        key: 'merchants',
        icon: 'fa fa-user',
        url: '/merchants',
      },
      {
        title: 'Orders',
        key: 'orders',
        icon: 'fa fa-shopping-cart',
        url: '/orders',
      },
    ],
  },

  {
    title: 'Content',
    key: 'content',
    icon: 'fa fa-list-ul',
    url: '',
    children: [
      {
        title: 'Category Sections',
        key: 'sections',
        guard: environment.superUsers,
        icon: 'fa fa-list-ul',
        url: '/sections',
      },
      {
        title: 'Sub Categories',
        key: 'categories',
        guard: environment.superUsers,
        icon: 'fa fa-list-ul',
        url: '/categories',
      },
      {
        title: 'Product Curations',
        key: 'curations',
        icon: 'fa fa-list-ul',
        url: '/curations',
      },
    ],
  },

  {
    title: 'Marketing & Admin',
    key: 'marketing',
    icon: 'fa fa-star',
    url: '',
    children: [
      {
        title: 'Discounts',
        key: 'discounts',
        icon: 'fa fa-qrcode',
        url: '/discounts',
      },
      {
        title: 'Vouchers',
        key: 'vouchers',
        icon: 'fa fa-qrcode',
        url: '/vouchers',
      },
      {
        title: 'Payouts',
        key: 'payouts',
        icon: 'fa fa-money',
        url: '/payouts',
      },
      {
        title: 'Reports',
        key: 'reports',
        icon: 'fa fa-file-text-o',
        url: '/reports',
      },
    ],
  },
  {
    title: 'Settings',
    key: 'settings',
    icon: 'fa fa-cog',
    url: '/settings',
  },
];
