import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    public token = new BehaviorSubject<any>(false);
    public user = new BehaviorSubject<any>(false);
    public httpOptions: any;
    public isActivatedNew = false;

    constructor(
        public router: Router,
        public http: HttpClient,
    ) {

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

    }

    public tokenObservable(): Observable<any> {
        return this.token.asObservable();
    }

    public userObservable(): Observable<any> {
        return this.user.asObservable();
    }

    public doLogin(data): any {

        data.grant_type = 'password';
        data.client_id = 'appClient';
        data.client_secret = '098f6bcd4621d373cade4e832627b4f6';

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        return new Promise((resolve, reject) => {
            this.http
                .post(environment.apiURL + 'oAuth/login', data, options)
                .subscribe(
                    (res) => {
                        resolve(res);
                    },
                    (err) => {
                        reject(err);
                    },
                );
        });

    }

    public logOut(): any {

        return new Promise((resolve, reject) => {

            localStorage.clear();
            this.user.next(false);
            this.token.next(false);
            this.router.navigateByUrl('/system/login');

        });

    }

    public TwoFactorCode(code): any {

        return new Promise((resolve, reject) => {

            resolve(true);

        });

    }

    public doActivate(data): any {

        data.grant_type = 'password';

        return new Promise((resolve, reject) => {
            this.http
                .post(environment.apiURL + 'oAuth/activate', data, this.httpOptions)
                .subscribe(
                    (res) => {
                        resolve(res);
                    },
                    (err) => {
                        reject(err);
                    },
                );
        });

    }

    public doRegister(data): any {

        data.grant_type = 'password';
        data.client_id = 'appClient';
        data.client_secret = '098f6bcd4621d373cade4e832627b4f6';

        return new Promise((resolve, reject) => {
            this.http
                .post(environment.apiURL + 'oAuth/register/customer', data, this.httpOptions)
                .subscribe(
                    (res) => {
                        resolve(res);
                    },
                    (err) => {
                        reject(err);
                    },
                );
        });

    }

    public getToken(): any {

        return new Promise((resolve, reject) => {

            this.desktopAuthCheck('token').then((res) => {
                console.log('Desktop ' + res.access_token);
                resolve(res.access_token);
            }).catch((err) => {
                console.log('no mobile token found');
                reject(false);
            });

        });

    }

    public getExpiry(): any {

        return new Promise((resolve, reject) => {

            this.desktopAuthCheck('token').then((res) => {
                console.log('Desktop ' + res.expires);
                resolve(res.expires);
            }).catch((err) => {
                console.log('no desktop expiry found');
                reject(false);
            });

        });

    }

    public getUser(): any {

        return new Promise((resolve, reject) => {

            this.desktopAuthCheck('user').then((res) => {
                console.log('User found ' + res.email);
                resolve(res);
            }).catch((err) => {
                console.log('No user found');
                reject(false);
            });

        });

    }

    public desktopAuthStore(data): any {

        return new Promise((resolve, reject) => {
            localStorage.setItem('token', JSON.stringify(data.token));
            localStorage.setItem('user', JSON.stringify(data.user));
            resolve(true);
        });

    }

    public desktopAuthCheck(item): any {

        return new Promise((resolve, reject) => {
            resolve(JSON.parse(localStorage.getItem(item)));
        });

    }

}
