import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'air-topbar-dark-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class TopbarDarkUserMenuComponent {
  public badgeCount = 7;
  public userName: string;
  public billingPlan: string;
  public email: string;
  public phone: string;
  public role: string;

  constructor(public authService: AuthService) {
    const userInfo = JSON.parse(sessionStorage.getItem('user'));
    this.userName = userInfo ? userInfo.displayName : 'Anonymous';
    this.billingPlan = 'Professional';
    this.email = userInfo ? userInfo.email : '';
    this.phone = userInfo ? userInfo.phoneNumber : '-';
    this.role = 'admin';
  }

  public badgeCountIncrease() {
    this.badgeCount = this.badgeCount + 1;
  }

  public logout() {
    this.authService.logOut();
  }
}
