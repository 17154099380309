import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'air-general-7',
  templateUrl: './7.component.html',
})
export class AirGeneral7Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
