<div class="card-body">
  <div class="d-flex mb-1">
    <div class="text-uppercase font-weight-bold mr-auto">
      Approvals
    </div>
    <div>
      4 Aprpovals are pending
    </div>
  </div>
  <div class="d-flex mb-2">
    <div class="font-size-24 font-weight-bold mr-auto">6</div>
    <div class="font-size-24">10</div>
  </div>
  <div class="progress">
    <div
      class="progress-bar bg-success"
      style="width: 60%"
      role="progressbar"
      aria-valuenow="60"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</div>
