import { Component, Input, OnInit } from "@angular/core";
import { Product } from "../../../Models/Product";
import { ApiService } from "../../../services/Api.service";

@Component({
  selector: "app-cuartion-products",
  templateUrl: "./cuartion-products.component.html",
  styleUrls: ["./cuartion-products.component.scss"]
})
export class CuartionProductsComponent implements OnInit {
  @Input() public type: "new" | "edit";
  @Input() public existingProducts: Product[];

  public productIDArray: number[] = [];
  public productArray: Product[] = [];
  public allProducts: Product[] = [];
  public loaded = false;
  public view = 1;

  public dtOptions: DataTables.Settings = {
    drawCallback: $event => {}
  };

  constructor(public api: ApiService) {}

  public ngOnInit(): void {
    if (this.existingProducts && this.existingProducts.length > 0) {
      this.productArray = this.existingProducts;
      for (const product of this.existingProducts) {
        this.productIDArray.push(product.id);
      }
      this.view = 2;
    }

    this.api.apiGet("core/admin/curations/potential").then(res => {
      this.allProducts = res;
      this.loaded = true;
    });
  }

  public removeProductFromArray(product: Product): void {
    const indexProductID = this.productIDArray.findIndex(
      element => element === product.id
    );
    const indexProduct = this.productArray.findIndex(
      element => element.id === product.id
    );

    this.productIDArray.splice(indexProductID, 1);
    this.productArray.splice(indexProduct, 1);
  }

  public addProductToArray(product: Product): void {
    this.productArray.push(product);
    this.productIDArray.push(product.id);
  }

  public isInArray(product: Product): boolean {
    const indexProductID = this.productIDArray.findIndex(
      element => element === product.id
    );

    return indexProductID > -1;
  }
}
