import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'air-general-1v1',
  templateUrl: './1v1.component.html',
})
export class AirGeneral1v1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
