<div>
  <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu">
    <span class="text-uppercase">{{language}}</span>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu (nzClick)="changeLanguage($event)">
      <li nz-menu-item key="en-US">
        <span class="text-uppercase font-size-12 mr-2">EN</span>
        English
      </li>
      <li nz-menu-item key="fr-FR">
        <span class="text-uppercase font-size-12 mr-2">FR</span>
        French
      </li>
      <li nz-menu-item key="ru-RU">
        <span class="text-uppercase font-size-12 mr-2">RU</span>
        Русский
      </li>
      <li nz-menu-item key="zh-CN">
        <span class="text-uppercase font-size-12 mr-2">CN</span>
        简体中文
      </li>
    </ul>
  </nz-dropdown-menu>
</div>