import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'air-list-6',
  templateUrl: './6.component.html',
  styleUrls: ['./6.component.scss'],
})
export class AirList6Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
