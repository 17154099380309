import {Component, Input, OnInit} from '@angular/core'

@Component({
  selector: 'air-general-1',
  templateUrl: './1.component.html',
})
export class AirGeneral1Component implements OnInit {
  @Input() info: any;

  constructor() {}
  ngOnInit() {}
}
