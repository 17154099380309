import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'air-general-12v2',
  templateUrl: './12v2.component.html',
})
export class AirGeneral12v2Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
