<div>
  <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu" (nzVisibleChange)="badgeCountIncrease()">
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>
        <strong>{{ 'topBar.profileMenu.hello' | translate }}, {{userName}}</strong>
        <div>
          <strong>
            {{ 'topBar.profileMenu.role' | translate }}::
          </strong>
          {{role}}
        </div>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>

        <a href="javascript: void(0);" (click)="logout()">
          <i class="menuIcon fe fe-log-out"></i>
          {{ 'topBar.profileMenu.logout' | translate }}:
        </a>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>
