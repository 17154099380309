import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'air-general-4',
  templateUrl: './4.component.html',
})
export class AirGeneral4Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
