<div class="topbar">
  <div class="mr-auto">
    <air-topbar-search></air-topbar-search>
  </div>
  <div class="mr-auto d-none">
    <air-topbar-issues-history></air-topbar-issues-history>
  </div>
  <div class="mb-0 mr-4 d-xl-block d-none">
    <air-topbar-status></air-topbar-status>
  </div>

  <div class="">
    <air-topbar-user-menu></air-topbar-user-menu>
  </div>
</div>
