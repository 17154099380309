export const environment = {
  production: true,
  hmr: false,
  apiURL: 'https://fetch-api.azurewebsites.net/',
  merchantURL: 'https://merchant.fetch.je/',
  websiteURL: 'https://fetch.je/',
  // apiURL: 'https://fetch-api-uat.azurewebsites.net/',
  // merchantURL: 'https://merchant-uat.fetch.je/',
  // websiteURL: 'https://uat.fetch.je/',
  superUsers: [
    'root',
    'nigel@tpagency.com',
  ],
};
