import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'air-general-2v3',
  templateUrl: './2v3.component.html',
})
export class AirGeneral2v3Component implements OnInit {
  constructor() {}
  public ngOnInit() {}
}
