<h5 class="mb-3">{{label}}</h5>
<hr>
<div>
  <ul class="list-unstyled">
    <li class="item" *ngFor="let item of data; let i = index;">
      <div class="itemLink" *ngIf="type === 'Merchant'">
        <div class="itemMeta font-weight-bold text-primary">
            #{{i + 1}}
        </div>
        <div class="mr-3 text-dark">
          <div>{{item.vendor.trading_name}}</div>
          <div class="text-muted">Total Orders: {{item.orders}}</div>
        </div>
        <div class="ml-auto">
          <div>{{item.total | currency : 'GBP'}}</div>
        </div>
      </div>
      <div class="itemLink" *ngIf="type === 'Product'">
        <div class="itemMeta font-weight-bold text-primary">
            #{{i + 1}}
        </div>
        <div class="mr-3 text-dark">
          <div>{{item.product_name}}</div>
          <div class="text-muted">Total Purchased: {{item.purchased_count}}</div>
        </div>
        <div class="ml-auto">
          <div>Price: {{item.base_price | currency : 'GBP'}}</div>
        </div>
      </div>
    </li>
  </ul>
</div>
