<form>

    <nz-form-item>
        <nz-form-label>Rule 1: Show Products from Product Category</nz-form-label>
        <nz-form-control>

            <nz-select name="rule1" [(ngModel)]="rules.byProductCategory"
                    [nzMaxTagCount]="3"
                    [nzMaxTagPlaceholder]="tagPlaceHolder"
                    nzMode="multiple"
                    [nzAutoClearSearchValue] = false
                    nzPlaceHolder="Please select">
                <nz-option *ngFor="let item of ProductCategories" [nzLabel]="item.category_name" [nzValue]="item.id"></nz-option>
            </nz-select>
            <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected</ng-template>

        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label>Rule 2: Show Products from Merchants</nz-form-label>
        <nz-form-control>

            <nz-select name="rule2" [(ngModel)]="rules.byMerchant"
                    [nzMaxTagCount]="3"
                    [nzMaxTagPlaceholder]="tagPlaceHolder"
                    nzMode="multiple"
                    [nzAutoClearSearchValue] = false
                    nzPlaceHolder="Please select">
                <nz-option *ngFor="let item of Vendors" [nzLabel]="item.trading_name" [nzValue]="item.id"></nz-option>
            </nz-select>
            <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected</ng-template>

        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label>Rule 3: Show Genuine Jersey Products?</nz-form-label>
        <nz-form-control>

            <nz-select name="genuine_jersey" [(ngModel)]="rules.genuineJersey"
                       nzPlaceHolder="Please select">
                <nz-option nzLabel="Yes" [nzValue]="1"></nz-option>
                <nz-option nzLabel="No" [nzValue]="0"></nz-option>
                <nz-option nzLabel="Show Any" [nzValue]="2"></nz-option>
            </nz-select>

        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label>How should the rules be handled</nz-form-label>
        <nz-form-control>

            <nz-select name="logic" [(ngModel)]="rules.logic"
                    nzPlaceHolder="Please select">
                <nz-option nzLabel="Products must meet both rules (AND Logic)" nzValue="AND"></nz-option>
                <nz-option nzLabel="Products must meet either rule (OR Logic)" nzValue="OR"></nz-option>
            </nz-select>

        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label>How Should products be ordered?</nz-form-label>
        <nz-form-control>

            <nz-select name="ordering" [(ngModel)]="rules.ordering"
                    nzPlaceHolder="Please select">
                <nz-option nzLabel="Randomly" nzValue="random"></nz-option>
                <nz-option nzLabel="Alphabetical Ascending (A-Z)" nzValue="a-z"></nz-option>
                <nz-option nzLabel="Alphabetical Descending (Z-A)" nzValue="z-a"></nz-option>
                <nz-option nzLabel="By Number of Sales" nzValue="sales"></nz-option>
                <nz-option nzLabel="Newest" nzValue="date-desc"></nz-option>
                <nz-option nzLabel="Oldest" nzValue="date_asc"></nz-option>
            </nz-select>

        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label>Should manually added products be shown first? (Pinned)</nz-form-label>
        <nz-form-control>

            <nz-select name="pin" [(ngModel)]="rules.pinProducts"
                    nzPlaceHolder="Please select">
                <nz-option nzLabel="Yes" [nzValue]="true"></nz-option>
                <nz-option nzLabel="No" [nzValue]="false"></nz-option>
            </nz-select>

        </nz-form-control>
    </nz-form-item>


</form>
