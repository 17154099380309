<div>
  <div class="d-flex flex-nowrap align-items-center">
    <div class="mr-auto">
      <div class="text-uppercase font-weight-bold font-size-24 text-dark">{{topFigure}}</div>
      <div class="font-size-18">{{topHeader}}</div>
    </div>
    <div class="ml-1">
      <i class="fe fe-star font-size-40"></i>
    </div>
  </div>
</div>
