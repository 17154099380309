import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import * as moment from "moment";

// @ts-ignore
@Injectable({
  providedIn: "root"
})
// @ts-ignore
export class ApiService {
  public hmac: string;
  public code: string;
  public shop: string;
  public apikey: string;
  public httpOptions: any;
  public uploadOptions: any;
  public downloadOptions: any;
  public blob: Blob;

  constructor(
    public router: Router,
    public auth: AuthService,
    public http: HttpClient
  ) {
    this.auth.tokenObservable().subscribe(data => {
      this.apikey = data;

      this.httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.apikey
        })
      };

      this.uploadOptions = {
        headers: new HttpHeaders({
          Authorization: "Bearer " + this.apikey
        })
      };

      this.downloadOptions = {
        responseType: "blob" as "json",
        headers: new HttpHeaders({
          Authorization: "Bearer " + this.apikey
        })
      };
    });
  }

  public payoutPrint(url) {
    const options = this.httpOptions;
    options.responseType = "blob" as "json";

    return new Promise((resolve, reject) => {
      this.http.get(environment.apiURL + "api/" + url, options).subscribe(
        data => {
          this.blob = new Blob([data], { type: "application/csv" });

          const downloadURL = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = downloadURL;
          link.download = "fetch-payout-" + moment().toISOString() + ".csv";
          link.click();

          resolve(true);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  public apiGet(url): any {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiURL + "api/" + url, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  public download(url): any {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiURL + "api/" + url, this.downloadOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  public apiPost(url, data): any {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + "api/" + url, data, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  public upload(url, data: File): any {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      formData.append("file", data, data.name);

      this.http
        .post(environment.apiURL + "api/" + url, formData, this.uploadOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  public login(data): any {
    data.client_id = "website";
    data.client_secret = "zAJyqYTUvQmuK9icLWOydCFvRJaaZWKN";
    data.grant_type = "password";

    return new Promise((resolve, reject) => {
      this.http
        .post("https://" + environment.apiURL + "oAuth/login", data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  public merchantReportCsv(url) {
    const options = this.httpOptions;
    options.responseType = "blob" as "json";

    return new Promise((resolve, reject) => {
      this.http.get(environment.apiURL + "api/" + url, options).subscribe(
        data => {
          this.blob = new Blob([data], { type: "application/csv" });

          const downloadURL = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = downloadURL;
          link.download = "merchant-report-" + moment().toISOString() + ".csv";
          link.click();

          resolve(true);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  public merchantReportPdf(url) {
    const options = this.httpOptions;
    options.responseType = "blob" as "json";

    return new Promise((resolve, reject) => {
      this.http.get(environment.apiURL + "api/" + url, options).subscribe(
        data => {
          this.blob = new Blob([data], { type: "application/pdf" });

          const downloadURL = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = downloadURL;
          link.download = "merchant-report-" + moment().toISOString() + ".pdf";
          link.click();

          resolve(true);
        },
        err => {
          reject(err);
        }
      );
    });
  }
}
