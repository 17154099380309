<div class="footer" [ngClass]="{footerFullWidth: isContentNoMaxWidth}">
  <div class="inner">
    <div class="row">
      <div class="col-md-8">
        <p>
          <strong>Fetch.JE - Admin </strong>
        </p>
        <p>
          This admin control panel provides un-paralleled access to the database. Access to this should only be given out to essential people.
        </p>
        <p>&copy; 2020 Developed By CodeFusion</p>
      </div>
      <div class="col-md-4">
        <div class="logo">
          <img src="assets/images/dark.svg" alt="InformX" style="height:40px;" />
        </div>
      </div>
    </div>
  </div>
</div>
