import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-counter-inprog',
  templateUrl: './2.component.html',
})
export class AirGeneral2Component implements OnInit {
  constructor() {}

  stat: number = 0

  ngOnInit() {
  }



}
