import { Injectable } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import {AuthService} from '../../../services/auth.service';

@Injectable({
  providedIn: 'root',
})

export class AuthGuard implements CanActivate {
  constructor(
      private auth: AuthService,
      private router: Router,
      public route: ActivatedRoute) {}

  public canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {

    return new Promise(((resolve, reject) => {

      console.log('AUTH GUARD READY');

      const promiseArray = [
        this.auth.getToken(),
        this.auth.getExpiry(),
        this.auth.getUser(),
      ];

      Promise.all(promiseArray).then((res) => {
        console.log('AUTH GUARD DONE');

        if (this.checkExpiry(res[1])) {
            this.auth.token.next(res[0]);
            resolve(true);
        } else {
          console.log('EXPIRED');
          this.router.navigate(['system/login'], {queryParams: {returnUrl: state.url}});
          reject(false);

        }

      }).catch((err) => {
        console.log('NO TOKEN FOUND');
        this.router.navigate(['system/login'], {queryParams: {returnUrl: state.url}});
        reject(false);

      });

    })); // END PROMISE

  }

  public checkExpiry(date): boolean {

    const now = moment();
    const expires = moment(date);
    return moment(now).isBefore(expires);

  }

}
