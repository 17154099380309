import {Component, Input, OnChanges, OnInit} from '@angular/core';

@Component({
  selector: 'air-progress-stat',
  templateUrl: './12.component.html',
})
export class AirGeneral12Component implements OnInit {

  @Input() public stat: number;
  @Input() public label: string;
  @Input() public currentGoal: number;

  public percentage: number;

  constructor() {}

  public ngOnInit() {

    if (this.stat !== null && this.currentGoal !== null){

      this.percentage = Math.round(this.stat / this.currentGoal * 100);

    }

  }


}
