<div>
  <p class="text-dark font-size-48 font-weight-bold mb-2">1206</p>
  <p class="text-uppercase text-muted mb-3">Total Applications Approved</p>
  <p class="mb-4">
    This shows your historical approvals.
  </p>
  <a href="javascript: void(0);" class="btn btn-outline-primary mb-1">
    View Approvals
  </a>
</div>
