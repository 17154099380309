import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-counter-inrev',
  templateUrl: './2v1.component.html',
})
export class AirGeneral2v1Component implements OnInit {
  constructor() {}

  stat: number = 0

  ngOnInit() {
  }

  getStat() {



  }
}
