import {Component, Input, OnInit} from '@angular/core';
import {CurationRules} from '../../../Models/CurationRules';
import {ProductCategory} from '../../../Models/ProductCategory';
import {Vendor} from '../../../Models/Vendor';
import {ApiService} from '../../../services/Api.service';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss'],
})
export class RulesComponent implements OnInit {

  @Input() public type: string;
  @Input() public existingRules: CurationRules;

  public rules: CurationRules;
  public ProductCategories: ProductCategory[];
  public Vendors: Vendor[];

  constructor(public api: ApiService) { }

  public ngOnInit(): void {

      if (this.existingRules){
          this.rules = this.existingRules;
      } else {
          this.rules = new CurationRules();
      }

      const promiseArray = [
        this.api.apiGet('public/stores/all'),
        this.api.apiGet('core/admin/curations/categories'),
      ];

      Promise.all(promiseArray).then((result) => {
        this.Vendors = result[0];
        this.ProductCategories = result[1];
      }).catch((err) => {

      });

  }


}
