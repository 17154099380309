<div class="position-relative">
  <div class="card-body">
    <div class="text-dark font-size-18 font-weight-bold mb-1">Year Profit</div>
    <div class="text-gray-6 mb-2">Revenue by location and date</div>
    <div class="font-weight-bold font-size-36 text-dark">$437,246.00</div>
  </div>
  <x-chartist
    [data]="chartData"
    [type]="'Line'"
    [options]="chartOptions"
    class="height-200"
  >
  </x-chartist>
</div>
