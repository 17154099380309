import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'air-list-3',
  templateUrl: './3.component.html',
  styleUrls: ['./3.component.scss'],
})
export class AirList3Component implements OnInit {

  @Input() public data: any[];
  @Input() public type: string;
  @Input() public label: string;

  constructor() {}
  public ngOnInit() {}
}
