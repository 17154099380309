import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'air-twotier-stat',
  templateUrl: './5.component.html',
})
export class AirGeneral5Component implements OnInit {

  @Input() public topHeader: string;
  @Input() public bottomHeader: string;
  @Input() public topFigure: string;
  @Input() public bottomFigure: string;

  constructor() {}

  public ngOnInit() {}
}
