<div *ngIf="loaded">

    <h6>You currently have {{productArray.length}} manually added products.</h6>

    <div class="d-flex my-4">

        <div class="special-tab" [class.active]="view === 1" (click)="view = 1">

            <h5>Search and Add Products</h5>

        </div>

        <div class="special-tab" [class.active]="view === 2" (click)="view  = 2">

            <h5>View / Remove Existing Products</h5>


        </div>

    </div>

    <div *ngIf="view === 1">

        <p>Showing all products below that are set to available belonging to merchants that are approved and online.</p>

        <table datatable [dtOptions]="dtOptions"  class="row-border hover">

            <thead>

            <tr>
                <th>Product Name</th>
                <th>Merchant</th>
                <th>Price</th>
                <th>Actions</th>
            </tr>

            </thead>

            <tbody>

            <tr class="text-dark" *ngFor="let item of allProducts">
                <td>{{item.product_name}}</td>
                <td><span *ngIf="item.vendor">{{item.vendor.trading_name}}</span></td>
                <td>
                    {{item.base_price | currency: 'GBP'}}
                </td>
                <td>
                    <button (click)="addProductToArray(item)" *ngIf="!isInArray(item)" nz-button nzType="primary">Add Product</button>
                    <h6 class="font-weight-bold m-0" *ngIf="isInArray(item)">Added</h6>
                </td>
            </tr>

            </tbody>

        </table>

    </div>

    <div *ngIf="view === 2">

        <p>These are all the products that are now in your curation.</p>


        <table datatable [dtOptions]="dtOptions"  class="row-border hover">

            <thead>

            <tr>
                <th>Product Name</th>
                <th>Merchant</th>
                <th>Price</th>
                <th>Actions</th>
            </tr>

            </thead>

            <tbody>

            <tr class="text-dark" *ngFor="let item of productArray">
                <td>{{item.product_name}}</td>
                <td><span *ngIf="item.vendor">{{item.vendor.trading_name}}</span></td>
                <td>
                    {{item.base_price | currency: 'GBP'}}
                </td>
                <td>
                    <button (click)="removeProductFromArray(item)" *ngIf="isInArray(item)" nz-button nzType="primary">Remove Product</button>
                </td>
            </tr>

            </tbody>
        </table>

    </div>


</div>

<div *ngIf="!loaded">

    <h3>We are loading products please wait...</h3>

</div>
