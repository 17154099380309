import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'air-general-3',
  templateUrl: './3.component.html',
})
export class AirGeneral3Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
