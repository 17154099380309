<div>
  <div class="font-weight-bold text-dark font-size-24">+90%</div>
  <div>Sales Rise</div>
  <x-chartist
    [data]="chartData"
    [type]="'Line'"
    [options]="chartOptions"
    class="height-200"
  >
  </x-chartist>
</div>
