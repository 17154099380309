import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {DataTablesModule} from 'angular-datatables';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import {CuartionProductsComponent} from './components/modals/cuartion-products/cuartion-products.component';
import {RulesComponent} from './components/modals/rules/rules.component';
import { StickyHeaderTopDirective } from './sticky-header-top.directive';
import { StickyHeaderDirective } from './sticky-header.directive';

const MODULES = [CommonModule, RouterModule, NgZorroAntdModule, TranslateModule, NgbModule];

@NgModule({
    imports: [...MODULES, DataTablesModule, FormsModule],
  declarations: [StickyHeaderDirective, StickyHeaderTopDirective, CuartionProductsComponent, RulesComponent],
  exports: [...MODULES, StickyHeaderDirective, StickyHeaderTopDirective, CuartionProductsComponent, RulesComponent],
})
export class SharedModule {}
