<div class="chat">
  <button type="button" (click)="toggle()"
    class="toggleButton btn btn-rounded btn-light text-nowrap text-dark font-weight-bold font-size-18">
    <i class="chatIcon fe fe-message-square text-blue mr-md-2"></i>
    <span class="d-none d-md-inline">Support Chat</span>
  </button>
  <div class="chatContainer" [ngClass]="isSupportChatOpen ? 'containerToggled' : ''">
    <div class="d-flex flex-wrap mb-2">
      <div class="text-dark font-size-18 font-weight-bold mr-auto">Support Chat</div>
      <button type="button" class="btn btn-link p-0 border-0" (click)="toggle()">
        <i class="fe fe-x-square font-size-21 align-middle text-gray-6"></i>
      </button>
    </div>
    <air-general-14></air-general-14>
  </div>
</div>