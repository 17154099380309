import {Component, Input, OnInit} from '@angular/core'

@Component({
  selector: 'air-general-12v1',
  templateUrl: './12v1.component.html',
})
export class AirGeneral12v1Component implements OnInit {
  @Input() info: any;

  constructor() {}
  ngOnInit() {}
}
